.App {

  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}
h1{
  text-align: center;
}
.App-link {
  color: #61dafb;
}

.form-text, h2{
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  /*background-color: #5c6aed !important;*/
  background-image: url("background1.jpeg");
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.mainContainer{
  background-color: rgb(53 53 53 / 60%) !important;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.5);
  padding-top: 50px;
  margin-bottom: 50px;

}


.card{
  border-radius: 30px;
}

.dashboardContainer {
  background-color: rgb(58 58 58 / 60%);
  border-radius: 5px;
  box-shadow: 0 5px 5px rgba(0, 172, 193, 0.2);
  padding: 20px;
  margin-bottom: 10px;
  margin-top: 30px;
  z-index: 2;
}

.balanceWallet{
  background-color: rgb(0, 172, 193); /* Example primary color from your design */
  color: #ffffff;
  padding: 5px 10px;
  margin-right: 10px;
  margin-top: 10px;
  display: inline-block;
  border-radius: 5px;
  z-index: 2;
}

.accordion-button{
  background-color: rgb(0, 172, 193); /* Example primary color from your design */
  color: #ffffff;
  border: none;
  border-radius: 5px;
}

.walletButton {
  background-color: rgb(0, 172, 193); /* Example primary color from your design */
  color: #ffffff;
  border: none;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
}

.walletButton:hover {
  background-color: #00ACC1; /* Darker shade for hover state */
}

.formLabel {
  color: #00ACC1;
  margin-bottom: 3px;
  font-size: 12px;
}

.formControl {
  border-radius: 30px;
  border: 1px solid #00ACC1;
  padding: 5px 15px;
}

.formSelect {
  border-radius: 30px;
  border: 1px solid #00ACC1;
  padding: 5px 15px;
}

.listGroup {
  margin-top: 20px;
}

.listGroupItem {
  background-color: #f7f7f7;
  border: none;
  margin-bottom: 10px;
  border-radius: 20px;
  padding: 15px;
  color: #333333;
}

.walletDetails {
  font-size: 11px;
  color: #666666;
}

.listGroup {
  padding: 0;
  margin: 0;
  border: none;
}

.listGroupItem {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
}

.titleHeader {
  color: #00ACC1;
  font-weight: 500;
  font-size: 30px;
  display: block;
  text-align: center;
  position: absolute;
  left: calc(50% - 50px);
  z-index: 1;
  top: 20px;
}

.titleHeader img {
  width: 100px;
}

.taskName {
  color: #00ACC1;
  font-weight: 500;
  font-size: 20px;
  display: block;
}
a{
  color: #00ACC1;
}
hr{
  border-color: #00ACC1;
  opacity: 1;
}

.taskDetails {
  color: #333;
  font-size: 12px;
  background: #f9f9f9; /* Light background for the detail section */
  border-radius: 6px;
  padding: 8px;
  margin-top: 4px;
  display: inline-block; /* To make the background wrap the text */
}

.detailItem {
  display: block; /* Each detail item is inline */
  margin-right: 8px; /* Spacing between detail items */
  line-break: anywhere;
}

.detailKey {
  font-weight: bold;
  margin-right: 4px; /* Spacing after the key */
}

/* Optional: Add an icon before each detail */
.detailIcon {
  display: inline-block;
  margin-right: 4px; /* Spacing after the icon */
  width: 16px; /* Fixed width for icons */
  height: 16px; /* Fixed height for icons */
  background-size: contain;
  background-repeat: no-repeat;
}

/* Example of how to use a specific icon for a detail key */
.detailIcon-recipientAddress {
  background-image: url('https://about.qvrse.io/assets/img/features/vr.svg'); /* Path to your address icon */
}

.detailIcon-amount {
  background-image: url('https://about.qvrse.io/assets/img/features/vr.svg'); /* Path to your amount icon */
}

.loggerContainer {
  max-height: 800px;
  overflow-y: auto;
  font-family: monospace;
}

.listGroupItemLogger {
  background-color: #e9ecef;
  color: #00ACC1;
  border: 1px solid #dee2e6;
  margin-bottom: 4px;
}

.list-group-item{
  font-size: 9px;
    border-radius: 30px !important;
    margin-bottom: 3px;
    background-color: transparent;
    color: #00ACC1;
    border: 0px;
}
.list-group-item .text-muted{

    color: #00ACC1 !important;
}


.status-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.yellow-circle {
  background-color: #fdfd18;
}
.green-circle {
  background-color: #01a201;
}
.red-circle {
  background-color: #d41212;
}

table tr td, table tr th{
  background-color: rgba(210,130,240, 0) !important;
}

.table>:not(caption)>*>*{

  color: #00ACC1 !important;
  border-bottom: 0px;
}